const iconPlus = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 1V15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 8H15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
const iconMinus = `<svg width="16" height="16" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1H15" stroke="#3678F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
document.addEventListener('DOMContentLoaded', function(){
    const accordionsList = document.querySelectorAll('.accordion');
    if (accordionsList){
        accordionsList.forEach(accordion => {
            const accordionList = accordion.querySelectorAll('.accordion__item');
            accordionList.forEach((item,index) => {
                const content = item.querySelector('.accordion__content');

                item.classList.add('open');
                const height = content.offsetHeight;
                item.classList.remove('open');

                const header = item.querySelector('.accordion__header');
                const icon = item.querySelector('.accordion__icon');
                header.addEventListener('click', () => {
                    item.classList.toggle('open');

                    if (item.classList.contains('open')) {
                        content.style.height = `${height}px`;
                        icon.innerHTML = iconMinus;
                    } else {
                        content.style.height = `${0}px`;
                        icon.innerHTML = iconPlus;
                    }
                })

                if (index === 0) {
                    item.classList.add('open');
                    content.style.height = `${height}px`;
                    icon.innerHTML = iconMinus;
                } else {
                    icon.innerHTML = iconPlus;
                }
            })
        })
    }
})