document.addEventListener('DOMContentLoaded', () => {

    const popupList = document.querySelectorAll('.popup');

    if (popupList) {
        popupList.forEach(popup => {
            const popupClose = document.querySelector('.popup__close');
            const popupIframeClose = document.querySelector('.js-iframe-close');
            const popupIframeFullScreen = document.querySelector('.js-iframe-full-screen');
            popupClose.addEventListener('click', () => {
                popup.classList.remove('open','full-screen');
            })
            popupIframeClose.addEventListener('click', () => {
                popup.classList.remove('open','full-screen');
            })
            popupIframeFullScreen.addEventListener('click', () => {
                popup.classList.add('full-screen');
            })
        })
    }

    const btnIframeList = document.querySelectorAll('.js-btn-iframe')

    if (btnIframeList) {
        const popupIframe = document.querySelector('.popup--iframe')
        btnIframeList.forEach(btnIframe => {
            btnIframe.addEventListener('click', (event) => {
                popupIframe.classList.add('open')
                const iframe = popupIframe.querySelector('iframe');
                iframe.src = event.target.getAttribute('data-url');
                const popupTitle = popupIframe.querySelector('.popup__title');
                popupTitle.innerText = event.target.getAttribute('data-title');
            })
        })
    }
})